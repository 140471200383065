var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (config, css, href) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libchat], [true]), false, true)+pug.attr("id", css.libchat, true, true)+" role=\"region\""+pug.attr("aria-labelledby", css.libchat_head, true, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libchat_head], [true]), false, true)+pug.attr("id", css.libchat_head, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = config.title || "Ask Us!") ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Ca" + (pug.attr("class", pug.classes([css.libchat_button_close], [true]), false, true)+" href=\"javascript:bgsu_libchat.close(true)\" aria-label=\"Close chat notifcation\"") + "\u003E\u003C\u002Fa\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libchat_body], [true]), false, true)) + "\u003E";
if (config.body) {
pug_html = pug_html + (null == (pug_interp = config.body) ? "" : pug_interp);
}
else {
pug_html = pug_html + "\u003Cimg" + (pug.attr("src", require("../svg/askus.svg"), true, true)+" alt=\"\" width=\"75\"") + "\u003E\u003Cbr\u003E";
if (config.message) {
pug_html = pug_html + (null == (pug_interp = config.message) ? "" : pug_interp);
}
else {
pug_html = pug_html + "BGSU library staff (real people, not an AI or bot) are\navailable. How can we help?";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libchat_foot], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("class", pug.classes([css.libchat_button], [true]), false, true)+pug.attr("href", href, true, true)+" target=\"_blank\""+pug.attr("onclick", `return bgsu_libchat.open(this.href,'width=${
                config.width || 400
            },height=${
                config.height || 500
            }')`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = config.accept || "Chat with us now") ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Ca" + (pug.attr("class", pug.classes([css.libchat_button], [true]), false, true)+" href=\"javascript:bgsu_libchat.close(true)\"") + "\u003E" + (pug.escape(null == (pug_interp = config.decline || "No thanks") ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined, "href" in locals_for_with ?
        locals_for_with.href :
        typeof href !== 'undefined' ? href : undefined));
    ;;return pug_html;};
module.exports = template;